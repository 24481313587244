@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400&display=swap');

@layer base{
    body{
        @apply font-[Inconsolata]
    }
   li{
    @apply px-4;
    @apply cursor-pointer

   }
}
.content-div {
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
 height: 250px;
}
.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 255, 205, 0.8),
        hsla(185, 57%, 29%, 0.8)   
    )!important;
}
li{
    color: rgb(84, 72, 72)
}